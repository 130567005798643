<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top ">
    <a class="navbar-brand" href="#" aria-label="Przejście na stronę główną">
        <img src="../../../assets/img/logo-3d.png" id="navbar-logoImage" alt="Logo firmy.">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop" aria-expanded="false" aria-label="Offcanvas navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarScroll">


      <div class="ms-auto navbar-buttons_wrapper">
          <ul class="navbar-nav">
              <li class="li-button-details">
                  <a class="nav-link button-details roboto-flex-300" routerLinkActive="button-active" href="/" href="/">Strona główna</a>
              </li>
              <li class="li-button-details">
                  <a class="nav-link button-details roboto-flex-300" routerLinkActive="button-active" href="oferta" href="oferta">Oferta</a>
              </li>
              <li class="li-button-details">
                  <a class="nav-link button-details roboto-flex-300 "  routerLinkActive="button-active" href="galeria" href="galeria">Galeria</a>
              </li>
              <li class="li-button-details">
                  <a class="nav-link button-details roboto-flex-300" routerLinkActive="button-active" href="kontakt" href="kontakt">Kontakt</a>
              </li>
              <li>
                  <div class="booksy_wrapper">
                      <a href="https://booksy.com/pl-pl/177565_pink-lips-martyna-rodasik_makijaz_11835_mikolow">
                          <div class="booksy_description">Umów termin na:</div>
                          <div class="booksy_logo"></div>
                      </a>
                  </div>
              </li>
          </ul>


      </div>
  </div>
</nav>

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasWithBackdrop" aria-labelledby="offcanvasWithBackdropLabel">
<div class="offcanvas-header">
  <h5 class="offcanvas-title" id="offcanvasWithBackdropLabel">Menu</h5>
  <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
</div>
<div class="offcanvas-body">
  <ul class="navbar-nav ">
    <li class="li-button-details">
        <a class="nav-link button-details roboto-flex-300" routerLinkActive="button-active" href="/" href="/">Strona główna</a>
    </li>
    <li class="li-button-details">
        <a class="nav-link button-details roboto-flex-300" routerLinkActive="button-active" href="oferta" href="oferta">Oferta</a>
    </li>
    <li class="li-button-details">
        <a class="nav-link button-details roboto-flex-300 "  routerLinkActive="button-active" href="galeria" href="galeria">Galeria</a>
    </li>
    <li class="li-button-details">
        <a class="nav-link button-details roboto-flex-300" routerLinkActive="button-active" href="kontakt" href="kontakt">Kontakt</a>
    </li>

      <li>
          <div class="booksy_wrapper" >
              <a href="https://booksy.com/pl-pl/177565_pink-lips-martyna-rodasik_makijaz_11835_mikolow">
                  <div class="booksy_description">Umów termin na:</div>
                  <div class="booksy_logo"></div>
              </a>
          </div>
      </li>
  </ul>
</div>
</div>
