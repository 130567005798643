import { Routes } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { OfertaComponent } from './components/oferta/oferta.component';
import { MakeupGlowupComponent } from './components/oferta/makeup-glowup/makeup-glowup.component';
import { PakietySlubneComponent } from './components/oferta/pakiety-slubne/pakiety-slubne.component';
import { SzkoleniaComponent } from './components/oferta/szkolenia/SzkoleniaComponent';
import { GaleriaComponent } from './components/galeria/galeria.component';
import { KontaktComponent } from './components/kontakt/kontakt.component';
import { NotFoundError } from 'rxjs';

export const routes: Routes = [ 

    {path: "", component: MainComponent},
    { path: "oferta", component: OfertaComponent},
    { path: "oferta/pakiety-slubne", component: PakietySlubneComponent}, 
    { path: "oferta/makeup-glowup", component: MakeupGlowupComponent},
    { path: "oferta/szkolenia", component: SzkoleniaComponent},
    { path: "galeria", component: GaleriaComponent},
    { path: "kontakt", component: KontaktComponent},
    { path: "*", component: MainComponent},
    { path: "**", component: MainComponent}
];
