<section>
    <header>
        <div class="part1 white" style="">
            <div class="container-lg text-center">
                <div class="taviraj-regular display-4">
                    Piękno kobiety jest cudowne,<br>W każdym odcieniu podkładu.
                </div>
                <div class="row" style="">

                    <div class="col-md-5 order-md-1 order-2 part1-textBox">
                        <p class="roboto-flex-200 part1-text1">POSTAW NA NAJWYŻSZĄ JAKOŚĆ USŁUG</p>
                        <p class="unbounded-100 part1-text2">MakeUp, Wizaż & Szkolenia</p>
                        <p class="karla-200 part1-text1 part1-text3">Zapewniamy kompleksowe podejście do makijażu,
                            uwzględniając
                            preferencje klientów oraz najnowsze trendy w świecie urody, aby stworzyć niepowtarzalny
                            efekt.
                            Dostarczamy usługi na terenie całego kraju. Obsługujemy osoby prywatne, firmy oraz eventy.
                        </p>
                    </div>

                    <div class="col-md-7 d-none d-md-block order-md-2 order-1">
                        <div style="display: flex; gap:40px">
                            <div class="photo-frame" id="part1-photo1">
                            </div>
                            <div class="photo-frame" id="part1-photo2">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 d-md-none d-flex order-md-2 order-1 relative-container position-relative">
                        <div style="display: contents; width: 100vw;">
                            <div class="photo-frame-left" id="part1-photo1">
                            </div>
                            <div class="photo-frame-right" id="part1-photo2">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Kolumna na mobilne (jedna pod drugą)
          <div class="col-md-12 d-md-none mt-3">
            <div class="bg-success p-3">
               Treść dla kolumny na mobilne 
              <h2>Mobile Content</h2>
            </div>
          </div>-->
            </div>
        </div>
    </header>
</section>

<!-- Part 2 -->
<section>
    <div class="part2 grand-gray" style="position: relative; padding-top: 30px; padding-bottom: 50px;">
        <div class="container ">
            <div class="taviraj-regular display-4 text-light part2-title">
                Jak to działa?
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="part2-howTo-square">
                        <div class="part2-howTo-text">
                            <div class="part2-howTo-bg">01 WYBIERZ USŁUGĘ</div>
                            Przeszukaj nasz katalog i wybierz interesującą Cię usługę.
                            Sprawdź jakie typy makijaży wykonujemy.
                            Przygotowaliśmy również pakiety makijażowe w wielu kombinacjach oraz szkolenia dla różnych
                            poziomów
                            zaawansowania.
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="part2-howTo-square">
                        <div class="part2-howTo-text">
                            <div class="part2-howTo-bg">02 DOBIERZ TERMIN</div>
                            Sprawdź wolne terminy i wybierz najbardziej wygodny Tobie. Zachęcamy do sprawdzania terminów
                            na platformie
                            Booksy wraz z możliwością wybrania i rezerwacją usługi.
                            Można również wysłać zapytanie drogą mailową, telefonicznie, za pomocą SMS, czatów na
                            Messenger lub
                            Instagram.
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="part2-howTo-square">
                        <div class="part2-howTo-text">
                            <div class="part2-howTo-bg">03 OCZEKUJ POTWIERDZENIA</div>
                            Poczekaj kilka minut na potwierdzenie wybranej usługi i już.
                            Pamiętaj, że po rezerwacji zawsze możesz się z nami skontaktować, aby uzyskać dodatkowe
                            informacje, zadać
                            pytania lub poinformować odnośnie zmian organizacyjnych.
                            Jesteśmy dla Ciebie zawsze dostępni!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Part3 -->
<section>
    <div class="part3 white" style="">
        <div class="container ">
            <div class="part3-photosSection">
                <div class="">
                    <div style="display: flex; gap:40px; justify-content: center;">
                        <div class="photo-frame" id="part3-photo1">
                        </div>
                        <div class="photo-frame-size-2" id="part3-photo2"
                            [style]="{ 'background-image': 'url(' + currentImage + ')', 'animation': 'fadeInOut 5s infinite' }">
                        </div>
                        <div class="photo-frame" id="part3-photo3">
                        </div>
                    </div>
                </div>
            </div>
            <div class="part3-titleBox text-center" style="">
                <p class="roboto-flex-200 part3-titleText1">DOŁĄCZ DO GRONA ZADOWOLONYCH KOBIET</p>
                <div class="unbounded-100 display-4">
                    Zaufaj najlepszym
                </div>
            </div>
            <div class="row" style="gap:0px">
                <div class="col-md-1"></div>
                <div class="col-md-5  karla-200 part3-description1">
                    <div style="font-weight: bold; font-size: 18px;">MAKIJAŻE & PAKIETY ŚLUBNE</div>
                    <p>Nasz zespół specjalistów ds. makijażu zapewni Ci perfekcyjny wygląd na każdą okazję - od ślubów
                        po urodziny
                        i
                        wieczorne przyjęcia.
                        Dzięki doświadczeniu i profesjonalnym produktom podkreślimy Twoje piękno, dostosowując makijaż
                        do Twoich
                        preferencji i stylu.
                        Niech nasz makijaż sprawi, że będziesz promienna i pewna siebie w najważniejszych chwilach
                        życia.</p>
                    <a href="oferta/">
                        <button class="button-black part3-button">Sprawdź ofertę</button>
                    </a>

                </div>
                <div class="col-md-5  karla-200 part3-description2">
                    <div style="font-weight: bold; font-size: 18px;">EVENTY & WSPÓŁPRACA</div>
                    <p style="min-height: 200px;">Kompleksowa obsługa i gwarancja perfekcyjnego wyglądu na potrzeby
                        sesji
                        zdjęciowych, eventów firmowych oraz
                        występów na żywo. Dzięki
                        naszemu doświadczeniu i umiejętnościom stworzymy makijaże, które podkreślą piękno i będą
                        doskonale
                        wyglądały zarówno na zdjęciach, jak i wśród reflektorów. Zapraszamy do współpracy!</p>
                    <a href="kontakt">
                        <button class="button-black part3-button">Skontaktuj się</button>
                    </a>
                </div>
                <div class="col-md-1"></div>
            </div>
        </div>
    </div>
</section>

<!-- Part4 -->
<section>
    <div class="part4 grand-gray">
        <div class="row">
            <div class="col-md-6">
                <div class="part4-rightSide">
                    <div id="part4-firstPhoto"></div>
                    <div id="part4-secondPhoto"></div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="part4-leftSide">
                    <p class="roboto-flex-200 part4-text1">ZADBAJ O ROZWÓJ. ZWIĘKSZ SWOJE KOMPETENCJE.</p>
                    <p class="unbounded-100 part4-text2">Warsztaty, szkolenia & lekcje indywidulane </p>
                    <p class="karla-200 part4-text1 part4-text3">
                        Profesjonalne szkolenia z makijażu, które pomogą Ci zdobyć nowe umiejętności i pewność siebie.
                        Dołącz do
                        naszej społeczności pasjonatów piękna i otrzymaj certyfikat potwierdzający Twoje nowe
                        kwalifikacje.
                        Zainwestuj w siebie i rozwijaj swoją karierę w świecie makijażu! Do wyboru wiele ścieżek
                        szkoleniowych.
                    </p>
                    <a href="oferta/szkolenia">
                        <button class="button-black-whiteBorder part3-button">Sprawdź szkolenia</button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- Part5 -->
<section>
    <div class="part5 white">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="part5-valueBox">
                        <div class="part5-value unbounded-100">7 lat</div>
                        <div class="part5-subtitle karla-200">Doświadczenia branżowego</div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="part5-valueBox">
                        <div class="part5-value unbounded-100">1 750+</div>
                        <div class="part5-subtitle karla-200">Wykonanych makijaży</div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="part5-valueBox">
                        <div class="part5-value unbounded-100">5.0 </div>
                        <div class="part5-subtitle karla-200">Średnia ocen </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>