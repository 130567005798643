import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { MetaService } from '../../services/meta.service';

@Component({
  selector: 'app-oferta',
  standalone: true,
  imports: [],
  templateUrl: './oferta.component.html',
  styleUrl: './oferta.component.css'
})
export class OfertaComponent {
  
  constructor(private location:Location, private metaService:MetaService) { }

  ngOnInit(){
    this.metaService.updateMetaTags("Pink Lips - Martyna Rodasik - Oferta", 
    "Zapoznaj się z naszą ofertą makijaży! Oferujemy pakiety ślubne, MakeUp wieczorowy, szkolenia oraz wiele więcej! Sprawdź szczegóły.")

  }

  isOnOfferPage(): any {
    const currentPath = this.location.path();
    return !this.location.path().includes('/oferta/');
  }
}

