import { Component } from '@angular/core';
import { EnvironmentService } from '../../services/environment.service';
import { MetaService } from '../../services/meta.service';

@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.css']
})
export class KontaktComponent {

  email:string = '';
  phoneNumber:string = '';

  constructor(public environment: EnvironmentService, private metaService:MetaService) {}

  ngOnInit(): void {
    
    this.email = this.environment.getEmail();
    this.phoneNumber = this.environment.getPhoneNumber();

    this.metaService.updateMetaTags("Pink Lips - Martyna Rodasik - Kontakt", 
    "Potrzebujesz więcej informacji na temat usługi makijażu? Telefon kontaktowy: " + this.phoneNumber)
  }
}
