import { Component } from '@angular/core';
import { EnvironmentService } from '../../../services/environment.service';
import { OfertaComponent } from '../oferta.component';
import { MetaService } from '../../../services/meta.service';

@Component({
  selector: 'app-pakiety-slubne',
  templateUrl: './pakiety-slubne.component.html',
  styleUrls: ['./pakiety-slubne.component.css'],
  standalone: true,
  imports: [OfertaComponent]
})
export class PakietySlubneComponent {

  email:string = '';
  phoneNumber:string = '';

  constructor(public environment: EnvironmentService, private metaService:MetaService) {}

  ngOnInit(): void {
    this.email = this.environment.getEmail();
    this.phoneNumber = this.environment.getPhoneNumber();

    this.metaService.updateMetaTags("Pink Lips - Martyna Rodasik - Oferta Pakiety Ślubne", 
    "Oferta pakietów ślubnych przygotowana specjalnie dla Was. Sprawdź przygotowane wariantny. Usługi wykonywane mobilnie u Panny Młodej.")

  }
}
