<section>
    <div class="ofertaDetails white ">
        <div class="row ofertaDetails-part1">
            <div class="col-md-6">
                <div class="ofertaDetails-part1-leftSide">
                    <div class="ofertaDetails-header-photo" id="makeUpGlowUp-header-first-photo"></div>
                    <div class="ofertaDetails-header-photo" id="makeUpGlowUp-header-second-photo"></div>
                </div>
            </div>
            <div class=" col-md-6">
                <div class="ofertaDetails-part1-rightSide">
                    <p class="title-firstTextBlack">WYGLĄDAJ WYJĄTKOWO NA KAŻDĄ OKAZJĘ.</p>
                    <p class="title-SecondTextBlack">MakeUp & GlowUp </p>
                    <p class="normalTextBlack text-align-justify">
                        Szukasz sposobu, aby poczuć się pewnie i olśniewająco w dniu swojego urodzin, ważnej
                        uroczystości, komunii lub innego wyjątkowego wydarzenia? Podkreśl swoje piękno na każdą okazję!
                        <b>Makeup & GlowUp to idealne rozwiązanie dla Ciebie!</b> Oferujemy szeroki wachlarz usług
                        makijażowych, które podkreślą Twoje naturalne piękno i dodadzą
                        Ci pewności siebie.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="ofertaDetails white">
        <div class="container ofertaDetails-part2 ">
            <div class="row">
                <div class="col-md-6">
                    <p class="title-TopicPageBlack">Na każdą okazję</p>
                    <p class="normalTextBlack text-align-justify"><b>Ważne uroczystości:</b> Ślub, chrzciny, rocznica,
                        bal sylwestrowy - każda ważna uroczystość wymaga odpowiedniej oprawy. Podkreśl swoją elegancję i
                        wyjątkowość dzięki perfekcyjnemu makijażowi, który podkreśli Twój blask.<br></p>
                    <p class="normalTextBlack text-align-justify"><b>Sesje zdjęciowe:</b>
                        Profesjonalny makijaż to klucz do udanej sesji zdjęciowej. Zadbaj o to, aby na zdjęciach
                        wyglądać olśniewająco i zachwycać swoim nienagannym wyglądem.<br></p>
                    <p class="normalTextBlack text-align-justify"><b>Wieczorne wyjście:</b> Przygotowujesz się na
                        imprezę, randkę lub wyjście do klubu? Podkreśl swoją pewność siebie i zmysłowość dzięki
                        wieczorowemu makijażowi, który oczaruje wszystkich wokół.<br></p>



                </div>
                <div class="col-md-6" id="dlaczego-warto-section">
                    <p class="title-TopicPageBlack">Dlaczego warto?</p>
                    <p class="normalTextBlack text-align-justify"><b>Doświadczenie i profesjonalizm:</b>
                        Posiadam wieloletnie doświadczenie w wykonywaniu makijaży. Znam najnowsze trendy i
                        techniki, aby Twój makijaż był idealnie dopasowany do Twojej urody i okazji.<br></p>
                    <p class="normalTextBlack text-align-justify"><b>Indywidualne podejście:</b> Do każdego
                        klienta podchodzę indywidualnie, aby stworzyć makijaż, który podkreśli jego naturalne piękno i
                        osobowość.<br></p>
                    <p class="normalTextBlack text-align-justify"><b>Wysokiej jakości kosmetyki:</b> Używam
                        wyłącznie sprawdzonych i renomowanych kosmetyków, które zapewniają trwałość i komfort
                        użytkowania.<br></p>
                    <p class="normalTextBlack text-align-justify"><b>Punktualność i rzetelność: </b> Możesz
                        być pewna, że dotrzymam terminu i zadbam o każdy szczegół, abyś wyglądała
                        olśniewająco.<br></p>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="ofertaDetails white">
        <div class="container ofertaDetails-part3">
            <div class="row">
                <div class="col-md-6 ofertaDetails-cennik-leftColText order-2 order-md-1">
                    <p class="title-TopicPageBlack">Usługa MakeUp & GlowUp</p>
                    <p class="normalTextBlack text-align-justify"><b>+ Makijaż okolicznościowy: </b> wyjątkowy dzień
                        wymaga wyjątkowej oprawy. Stwórz perfekcyjny makijaż na każdą okoliczność.</p>
                    <p class="normalTextBlack text-align-justify"><b>+ Makijaż biznesowy:</b> idealny sposób na zrobienie
                        dobrego wrażenia i podkreślenie swojej kompetencji podczas ważnych spotkań i wydarzeń
                        zawodowych.</p>
                    <p class="normalTextBlack text-align-justify"><b>+ Makijaż na sesję zdjęciową:</b> z dbałością o
                        detale sprawie, że na zdjęciach będziesz wyglądać olśniewająco, a Twoja
                        uroda zostanie idealnie podkreślona.</p>
                    <div class="cena-box">
                        <div class="unbounded-100 cena-box-cenaTitle">Cena:</div>
                        <div class="unbounded-100 cena-box-cenaPrice">200zł</div>
                    </div>
                </div>
                <div class="col-md-6 order-1 order-md-2">
                    <div class="photo-frame ofertaDetails-cennik-photo" id="oferta-makeup-glowup-cennik-firstPhoto" >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="ofertaDetails white">
        <div class="container ofertaDetails-part3">
            <div class="row normalTextBlack text-align-justify">
                Do każdej cenny doliczane są koszty dojazdu. Cena ta
                    jest zależna od dystansu i ustalana indywidualnie. <br>
                    Jeśli jesteś zainteresowana i chcesz skorzystać z usług lub chcesz poznać więcej szczegółów, to zapraszam do kontaktu!<br><br>
                    Telefon: <b>{{phoneNumber}} </b><br>
                    Email: <b>{{email}}</b> <br><br>                    
            </div>
        </div>
    </div>
</section>

<app-oferta></app-oferta>