import { Component } from '@angular/core';
import { EnvironmentService } from '../../../services/environment.service';
import { OfertaComponent } from '../oferta.component';
import { MetaService } from '../../../services/meta.service';


@Component({
  selector: 'app-makeup-glowup',
  templateUrl: './makeup-glowup.component.html',
  styleUrls: ['./makeup-glowup.component.css'],
  standalone: true,
  imports: [OfertaComponent],
})
export class MakeupGlowupComponent {

  email:string = '';
  phoneNumber:string = '';

  constructor(public environment: EnvironmentService, private metaService:MetaService) {}

  ngOnInit(): void {
    this.email = this.environment.getEmail();
    this.phoneNumber = this.environment.getPhoneNumber();

    this.metaService.updateMetaTags("Pink Lips - Martyna Rodasik - Oferta Usługi Makeup & GlowUp", 
    "Oferta przygotowana specjalnie dla Ciebie naszych makijaży mobilnych. Sprawdź wybrane pozycje usługi Makeup & Glowup!")

  }
}
