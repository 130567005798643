import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  
  private url = "https://pink-lips.com.pl"
  private email: string = 'martyna@pink-lips.com.pl';
  private phoneNumber: string = '+48 666 351 483';

  constructor() { }

  getEmail(): string {
    return this.email;
  }

  getPhoneNumber(): string {
    return this.phoneNumber;
  }

  getUrl(): string{
    return this.url;
  }
}