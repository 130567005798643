<section>
    <div class="oferta-first">
        <div class="oferta-first-FullSizeImage">
        </div>
        <div class="oferta-first-textBox">
            <p class="roboto-flex-200 oferta-first-textFirst">GALERIA PRAC</p>
            <p class="unbounded-100 oferta-first-textSecond">MakeUp, Wizaż & Szkolenia</p>
        </div>
    </div>
</section>

<section>
    <div class="oferta-second white">
        <div class="container">
            <div class="title-TopicPageBlack">Prace ślubne, panny młode</div>
            <div class="row">
                @for(photo of prace_slubne_photos; track photo.url){
                <div class="col-md-4 ">
                    <a href="{{path + photo.url}}" data-lightbox="prace-slubne">
                        <div class="galeria-border-box">
                            <div class="photo-frame galeria-photos"
                                [style.backgroundImage]="'url(' + path + photo.url + ')'"> </div>
                        </div>
                    </a>
                </div>
                }
            </div>
        </div>
    </div>
</section>


<section>
    <div class="oferta-second white">
        <div class="container">
            <div class="title-TopicPageBlack">MakeUp & GlowUp</div>
            <div class="row">
                @for(photo of makeup_glowup_photos; track photo.url){
                <div class="col-md-4">
                    <a href="{{path + photo.url}}" data-lightbox="makeup-glowup">
                        <div class="galeria-border-box">
                            <div class="photo-frame galeria-photos"
                                [style.backgroundImage]="'url(' + path + photo.url + ')'"> </div>
                        </div>
                    </a>
                </div>
                }
            </div>
        </div>
    </div>
</section>


<!-- <section>
    <div class="oferta-second white">
        <div class="container">
            <div class="title-TopicPageBlack">Szkolenia | Warsztaty</div>
            <div class="row">
                <div class="col-md-4 " *ngFor="let photo of szkolenia_warsztaty_photos">
                    <a href="{{path + photo.url}}" data-lightbox="szkolenia-warsztaty">
                        <div class="galeria-border-box">
                            <div class="photo-frame galeria-photos" [style.backgroundImage] = "'url(' + path + photo.url + ')'"> </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section> -->