<section>
    <div class="ofertaDetails white ">
        <div class="row kontakt-part1">
            <div class="col-md-6">
                <div class="kontakt-part1-leftSide">
                    <div class="kontakt-mainPhoto" id="kontakt-mainPhoto"></div>
                </div>
            </div>
            <div class=" col-md-6">
                <div class="ofertaDetails-part1-rightSide">
                    <p class="title-firstTextBlack">MARTYNA RODASIK - MAKIJAŻ MIKOŁÓW</p>
                    <p class="title-SecondTextBlack">PINK LIPS</p>
                    <p class="normalTextBlack text-align-justify">
                        <b>Pink Lips Martyna Rodasik</b><br>
                        43-190 Mikołów<br>
                        ul. Równoległa 45<br>
                        NIP: 635-186-40-31<br><br>



                        <b>Telefon:</b> {{phoneNumber}}<br>
                        <b>Email:</b> {{email}}<br>
                    </p>
                    <div style="height: 1px; background-color: #ff8eb1; margin-top:40px;margin-bottom: 40px;"></div>
                    <p class="normalTextBlack text-align-justify">
                        Masz pytania dotyczące moich usług? Chcesz nawiązać współpracę lub uzyskać indywidualną ofertę?
                        A może po prostu chcesz dowiedzieć się czegoś więcej?<br><br>
                        <b>Skontaktuj się ze mną!</b><br><br>
                        Chętnie odpowiem na wszystkie Twoje pytania i pomogę Ci wybrać idealną usługę dla Twoich potrzeb.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>