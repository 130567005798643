<section>
    <div class="ofertaDetails white ">
        <div class="row ofertaDetails-part1">
            <div class="col-md-6">
                <div class="ofertaDetails-part1-leftSide">
                    <div class="ofertaDetails-header-photo" id="pakietySlubne-header-first-photo"></div>
                    <div class="ofertaDetails-header-photo" id="pakietySlubne-header-second-photo"></div>
                </div>
            </div>
            <div class=" col-md-6">
                <div class="ofertaDetails-part1-rightSide">
                    <p class="title-firstTextBlack">TWÓJ WYJĄTKOWY DZIEŃ W NASZYCH RĘKACH.</p>
                    <p class="title-SecondTextBlack">Pakiety Ślubne </p>
                    <p class="normalTextBlack text-align-justify">
                        <b>Wielki dzień zbliża się wielkimi krokami.</b> Ślub to wyjątkowa chwila, która na zawsze
                        pozostanie w
                        Twojej pamięci. Pragniesz wyglądać olśniewająco, czuć się pewnie i komfortowo przez cały dzień i
                        noc? Zaufaj profesjonalistce i skorzystaj z pakietów ślubnych, które zapewnią Ci kompleksową
                        opiekę w tym wyjątkowym dniu. Inwestuj w swój wymarzony ślub i wybierz makijaż, który podkreśli
                        Twoje
                        piękno i doda Ci pewności siebie. Zadbaj o to, aby Twój ślub był wyjątkowym dniem, który na
                        zawsze pozostanie w Twojej pamięci.
                        Zaufaj profesjonalistce i wybierz makijaż, który podkreśli Twoje piękno!
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="ofertaDetails white">
        <div class="container ofertaDetails-part2 ">
            <div class="row">
                <div class="col-md-6">
                    <p class="title-TopicPageBlack">Co ofertuję?</p>
                    <p class="normalTextBlack text-align-justify"><b>Makijaż próbny:</b> Podczas spotkania
                        wspólnie ustalimy styl makijażu,
                        dobierzemy
                        odpowiednie kolory
                        i kosmetyki, abyś w dniu ślubu czuła się pewnie i komfortowo.<br></p>
                    <p class="normalTextBlack text-align-justify"><b>Makijaż ślubny:</b> W dniu Twojego ślubu
                        zadbam o każdy detal, abyś
                        wyglądała
                        perfekcyjnie.
                        Wykorzystam najwyższej jakości kosmetyki, które zapewnią trwałość makijażu przez cały dzień
                        i
                        noc.<br></p>
                    <p class="normalTextBlack text-align-justify"><b>Makijaże dla bliskich:</b> Zapewnię
                        makijaże dla Twoich świadkowych,
                        mamy, teściowej lub
                        innych
                        bliskich osób, które tego dnia będą Ci towarzyszyć.<br></p>



                </div>
                <div class="col-md-6" id="dlaczego-warto-section">
                    <p class="title-TopicPageBlack">Dlaczego warto?</p>
                    <p class="normalTextBlack text-align-justify"><b>Doświadczenie i profesjonalizm:</b>
                        Posiadam wieloletnie doświadczenie w wykonywaniu makijaży ślubnych. Znam najnowsze trendy i
                        techniki, aby Twój makijaż był idealnie dopasowany do Twojej urody i okazji.<br></p>
                    <p class="normalTextBlack text-align-justify"><b>Indywidualne podejście:</b> Do każdego
                        klienta podchodzę indywidualnie, aby stworzyć makijaż, który podkreśli jego naturalne piękno i
                        osobowość.<br></p>
                    <p class="normalTextBlack text-align-justify"><b>Wysokiej jakości kosmetyki:</b> Używam
                        wyłącznie sprawdzonych i renomowanych kosmetyków, które zapewniają trwałość i komfort
                        użytkowania.<br></p>
                    <p class="normalTextBlack text-align-justify"><b>Punktualność i rzetelność: </b> Możesz
                        być pewna, że dotrzymam terminu i zadbam o każdy szczegół, abyś w dniu ślubu wyglądała
                        olśniewająco.<br></p>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="ofertaDetails white">
        <div class="container ofertaDetails-part3">
            <div class="row">
                <div class="col-md-6 ofertaDetails-cennik-leftColText order-2 order-md-1">
                    <p class="title-TopicPageBlack">Pakiet Biały</p>
                    <p class="normalTextBlack text-align-justify"><b>+ Makijaż próbny: </b> przeprowadzenie wywiadu,
                        dobranie kolorytu, wsakazówki dbania o cerę ustalenie szczegółów.</p>
                    <p class="normalTextBlack text-align-justify"><b>+ Makijaż ślubny:</b> profesjonalne wykonanie
                        makijażu, korekta dekoltu</p>
                    <p class="normalTextBlack text-align-justify"><b>+ Makijaż dla bliskiego:</b> jedna z Twoich
                        najbliższych osób zostanie również pomalowana<br></p>
                    <p class="normalTextBlack text-align-justify"><b>+ Zniżka:</b> -10% na makijaż do sesji
                        ślubnej</p>
                    <div class="cena-box">
                        <div class="unbounded-100 cena-box-cenaTitle">Cena:</div>
                        <div class="unbounded-100 cena-box-cenaPrice">850zł</div>
                    </div>
                </div>
                <div class="col-md-6 order-1 order-md-2">
                    <div class="photo-frame ofertaDetails-cennik-photo"
                        id="oferta-pakiety-slubne-cennik-firstPhoto" ></div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="ofertaDetails white">
        <div class="container ofertaDetails-part3">
            <div class="row">
                <div class="col-md-6 order-2 ofertaDetails-cennik-leftColText">
                    <p class="title-TopicPageBlack">Pakiet Złoty</p>
                    <p class="normalTextBlack text-align-justify"><b>+ Makijaż próbny: </b> przeprowadzenie wywiadu,
                        dobranie kolorytu, wsakazówki dbania o cerę ustalenie szczegółów</p>
                    <p class="normalTextBlack text-align-justify"><b>+ Makijaż ślubny:</b> profesjonalne wykonanie
                        makijażu, korekta dekoltu</p>
                    <p class="normalTextBlack text-align-justify"><b>+ Makijaż dla bliskich:</b> dwoje z Twoich
                        najbliższych osób zostanie również pomalowana<br></p>
                    <p class="normalTextBlack text-align-justify"><b>+ Zniżka:</b> -10% na makijaż do sesji
                        ślubnej</p>
                    <div class="cena-box">
                        <div class="unbounded-100 cena-box-cenaTitle">Cena:</div>
                        <div class="unbounded-100 cena-box-cenaPrice">1 100zł</div>
                    </div>
                </div>
                <div class="col-md-6 order-1">
                    <div class="photo-frame ofertaDetails-cennik-photo"
                        id="oferta-pakiety-slubne-cennik-secondPhoto" ></div>
                </div>
            </div>
        </div>
    </div>
</section>



<section>
    <div class="ofertaDetails white">
        <div class="container ofertaDetails-part3">
            <div class="row">
                <div class="col-md-6 ofertaDetails-cennik-leftColText order-2 order-md-1">
                    <p class="title-TopicPageBlack">Pakiet Diamentowy</p>
                    <p class="normalTextBlack text-align-justify"><b>+ Makijaż próbny: </b> przeprowadzenie wywiadu,
                        dobranie kolorytu, wsakazówki dbania o cerę ustalenie szczegółów</p>
                    <p class="normalTextBlack text-align-justify"><b>+ Makijaż ślubny:</b> profesjonalne wykonanie
                        makijażu, korekta dekoltu</p>
                    <p class="normalTextBlack text-align-justify"><b>+ Makijaż dla bliskich:</b> trzech z Twoich
                        najbliższych osób zostanie również pomalowana<br></p>
                    <p class="normalTextBlack text-align-justify"><b>+ Niezbędnik poprawkowy:</b> szminka, puder i
                        płatki róz<br></p>
                    <p class="normalTextBlack text-align-justify"><b>+ Zniżka:</b> -10% na makijaż do sesji ślubnej</p>
                    <div class="cena-box">
                        <div class="unbounded-100 cena-box-cenaTitle">Cena:</div>
                        <div class="unbounded-100 cena-box-cenaPrice">1 350zł</div>
                    </div>
                </div>
                <div class="col-md-6 order-1 order-md-2">
                    <div class="photo-frame ofertaDetails-cennik-photo"
                        id="oferta-pakiety-slubne-cennik-thirdPhoto" ></div>
                </div>
            </div>
        </div>
    </div>
</section>


<section>
    <div class="ofertaDetails white">
        <div class="container ofertaDetails-part3">
            <div class="row normalTextBlack text-align-justify">
                Do każdej cenny doliczane są koszty dojazdu. Cena ta
                    jest zależna od dystansu i ustalana indywidualnie. <br>
                    Jeśli jesteś zainteresowana i chcesz skorzystać z usług lub chcesz poznać więcej szczegółów, to zapraszam do kontaktu!<br><br>
                    Telefon: <b>{{phoneNumber}} </b><br>
                    Email: <b>{{email}}</b> <br><br>                    
            </div>
        </div>
    </div>
</section>

<app-oferta></app-oferta>