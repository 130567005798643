import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MetaService } from '../../services/meta.service';
import { EnvironmentService } from '../../services/environment.service';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.css']
})
export class GaleriaComponent {
   
  email:string = "";
  
  constructor(private metaService:MetaService) { }

  ngOnInit(){
    

    this.metaService.updateMetaTags("Pink Lips - Martyna Rodasik - Galeria", 
    "Sprawdź moje portfolio! Zobacz wykoanne przeze mnie makijaże na Pannach Młodych i Modelkach. ")
  }

  readonly path = "../../../assets/img/photos/galeria/";

  prace_slubne_photos = [
    {name:"Panna Młoda 1", url: "prace-slubne/g_ps_001.jpg"},
    {name:"Panna Młoda 2", url: "prace-slubne/g_ps_002.jpg"},
    {name:"Panna Młoda 3", url: "prace-slubne/g_ps_003.jpg"},
    {name:"Panna Młoda 4", url: "prace-slubne/g_ps_004.jpg"},
    {name:"Panna Młoda 5", url: "prace-slubne/g_ps_005.jpg"},
    {name:"Panna Młoda 6", url: "prace-slubne/g_ps_006.jpg"},
    {name:"Panna Młoda 7", url: "prace-slubne/g_ps_007.jpg"},
    {name:"Panna Młoda 8", url: "prace-slubne/g_ps_008.jpg"},
    {name:"Panna Młoda 9", url: "prace-slubne/g_ps_009.jpg"},
  ]

  makeup_glowup_photos = [
    {name:"MakeUp & GlowUp 1", url: "makeup-glowup/g_mg_001.jpg"},
    {name:"MakeUp & GlowUp 2", url: "makeup-glowup/g_mg_002.jpg"},
    {name:"MakeUp & GlowUp 3", url: "makeup-glowup/g_mg_003.jpg"},
    {name:"MakeUp & GlowUp 4", url: "makeup-glowup/g_mg_004.jpg"},
    {name:"MakeUp & GlowUp 5", url: "makeup-glowup/g_mg_005.jpg"},
    {name:"MakeUp & GlowUp 6", url: "makeup-glowup/g_mg_006.jpg"},
    {name:"MakeUp & GlowUp 7", url: "makeup-glowup/g_mg_007.jpg"},
    {name:"MakeUp & GlowUp 8", url: "makeup-glowup/g_mg_008.jpg"},
    {name:"MakeUp & GlowUp 9", url: "makeup-glowup/g_mg_009.jpg"},
    {name:"MakeUp & GlowUp 10", url: "makeup-glowup/g_mg_010.jpg"},
    {name:"MakeUp & GlowUp 11", url: "makeup-glowup/g_mg_011.jpg"},
    {name:"MakeUp & GlowUp 12", url: "makeup-glowup/g_mg_012.jpg"},
    {name:"MakeUp & GlowUp 13", url: "makeup-glowup/g_mg_013.jpg"},
  ]

  szkolenia_warsztaty_photos = [
    {name:"Panna Młoda 1", url: "1.jpg"},
    {name:"Panna Młoda 2", url: "4.jpg"},
    {name:"Panna Młoda 3", url: "3.jpg"},
    {name:"Panna Młoda 4", url: "5.jpg"},
    {name:"Panna Młoda 4", url: "5.jpg"},
    {name:"Panna Młoda 4", url: "5.jpg"},
  ]


}
