<section>
    <div class="ofertaDetails white ">
        <div class="row ofertaDetails-part1">
            <div class="col-md-6">
                <div class="ofertaDetails-part1-leftSide">
                    <div class="ofertaDetails-header-photo" id="szkolenia-header-first-photo"></div>
                    <div class="ofertaDetails-header-photo" id="szkolenia-header-second-photo"></div>
                </div>
            </div>
            <div class=" col-md-6">
                <div class="ofertaDetails-part1-rightSide">
                    <p class="title-firstTextBlack">ZADBAJ O ROZWÓJ. ZWIĘKSZ SWOJE KOMPETENCJE.</p>
                    <p class="title-SecondTextBlack">Warsztaty, szkolenia & lekcje indywidulane </p>
                    <p class="normalTextBlack text-align-justify">
                        Profesjonalne szkolenia z makijażu, które pomogą Ci zdobyć nowe umiejętności i pewność siebie.
                        Dołącz do naszej społeczności pasjonatów piękna i otrzymaj certyfikat potwierdzający Twoje nowe
                        kwalifikacje. Zainwestuj w siebie i rozwijaj swoją karierę w świecie makijażu! Do wyboru wiele
                        ścieżek szkoleniowych.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="ofertaDetails white">
        <div class="container ofertaDetails-part2 ">
            <div class="row">
                <div class="col-md-6">
                    <p class="title-TopicPageBlack">Co ofertuję?</p>
                    <p class="normalTextBlack text-align-justify"><b>Materiały dydaktyczne w postaci mojego
                            poradnika:</b> Otrzymasz ode mnie autorski poradnik makijażowy, który będzie Twoim
                        kompendium wiedzy i źródłem inspiracji. Zawarte w nim informacje i wskazówki pomogą Ci utrwalić
                        zdobytą wiedzę i samodzielnie wykonywać perfekcyjne makijaże.<br></p>
                    <p class="normalTextBlack text-align-justify"><b>Indywidualne podejście:</b> Podczas każdego
                        szkolenia i lekcji skupiam się na Twoich indywidualnych potrzebach i celach. Dostosowuję program
                        nauczania do Twojego poziomu zaawansowania i stylu uczenia się, aby zapewnić Ci maksymalną
                        efektywność nauki.<br></p>
                    <p class="normalTextBlack text-align-justify"><b>Wiedzę teoretyczną i techniki praktyczne:</b> Na
                        naszych szkoleniach zdobędziesz zarówno solidną wiedzę teoretyczną na temat makijażu, jak i
                        praktyczne umiejętności wykonywania różnych technik makijażowych. Nauczysz się dobierać
                        odpowiednie kosmetyki do swojego typu cery i kształtu twarzy, a także poznasz tajniki
                        profesjonalnego makijażu.<br></p>



                </div>
                <div class="col-md-6" id="dlaczego-warto-section">
                    <p class="title-TopicPageBlack"> </p>
                    <p class="normalTextBlack text-align-justify"><b>Certyfikację:</b>
                        Otrzymanie certyfikatu potwierdzającego Twoje nowe kwalifikacje to cenne wyróżnienie, które
                        będzie cennym atutem na rynku pracy. Certyfikat ten poświadcza, że ukończyłaś profesjonalne
                        szkolenie makijażu i posiadasz niezbędną wiedzę i umiejętności do wykonywania makijażu na
                        wysokim poziomie. Może być również doskonałym uzupełnieniem Twojego portfolio, jeśli planujesz
                        prowadzić
                        własną działalność gospodarczą jako makijażystka. Zwiększ swoje szanse na sukces w branży
                        kosmetycznej! <br></p>
                    <p class="normalTextBlack text-align-justify"><b>Porady odnośnie doboru kosmetyków: </b>
                        Pomogę Ci w doborze odpowiednich kosmetyków do Twojego typu
                        cery, potrzeb i budżetu. Otrzymasz ode mnie rekomendacje najlepszych produktów na rynku i
                        wskazówki dotyczące pielęgnacji cery.<br></p>
                    <p class="normalTextBlack text-align-justify"><b>Moje wsparcie: </b>
                        Jestem do Twojej dyspozycji przed, w trakcie i po szkoleniu. Chętnie odpowiem na wszystkie Twoje
                        pytania, rozwieję wątpliwości i pomogę Ci w rozwiązaniu problemów.<br></p>

                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="ofertaDetails white">
        <div class="container ofertaDetails-part3">
            <div class="row">
                <div class="col-md-6 ofertaDetails-cennik-leftColText order-2 order-md-1">
                    <p class="title-TopicPageBlack">Ścieżka podstawowa</p>
                    <p class="normalTextBlack text-align-justify"><b>+</b> Idealna dla osób początkujących, które chcą
                        nauczyć się podstaw makijażu.</p>
                    <p class="normalTextBlack text-align-justify"><b>+</b> Nauczysz się wykonywać makijaż dzienny i
                        wieczorowy.</p>
                    <p class="normalTextBlack text-align-justify"><b>+</b> Otrzymasz porady dotyczące pielęgnacji cery i
                        doboru kosmetyków.<br></p>
                    <p class="normalTextBlack text-align-justify"><b>+</b> Jedno spotkanie, 5 godzin.</p>
                    <div class="cena-box">
                        <div class="unbounded-100 cena-box-cenaTitle">Cena:</div>
                        <div class="unbounded-100 cena-box-cenaPrice">450zł</div>
                    </div>
                </div>
                <div class="col-md-6 order-1 order-md-2">
                    <div class="photo-frame ofertaDetails-cennik-photo" id="oferta-szkolenia-cennik-firstPhoto" >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="ofertaDetails white">
        <div class="container ofertaDetails-part3">
            <div class="row">
                <div class="col-md-6 order-2 ofertaDetails-cennik-leftColText">
                    <p class="title-TopicPageBlack">Ścieżka zaawansowana</p>
                    <p class="normalTextBlack text-align-justify"><b>+</b> Idealna dla osób, które chcą
                        rozwijać swoje umiejętności tworzenia makijażu.</p>
                    <p class="normalTextBlack text-align-justify"><b>+</b> Nauczysz się zaawansowanych techinik. Pokaże
                        Ci najlepsze triki na tworzenie wyjątkowych rzeczy.</p>
                    <p class="normalTextBlack text-align-justify"><b>+</b> Otrzymasz porady dotyczące pielęgnacji cery i
                        doboru kosmetyków.<br></p>
                    <p class="normalTextBlack text-align-justify"><b>+</b> Dwa spotkania, 2x5 godzin.</p>
                    <div class="cena-box">
                        <div class="unbounded-100 cena-box-cenaTitle">Cena:</div>
                        <div class="unbounded-100 cena-box-cenaPrice">900zł</div>
                    </div>
                </div>
                <div class="col-md-6 order-1">
                    <div class="photo-frame ofertaDetails-cennik-photo" id="oferta-szkolenia-cennik-secondPhoto" >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="ofertaDetails white">
        <div class="container ofertaDetails-part3">
            <div class="row">
                <div class="col-md-6 ofertaDetails-cennik-leftColText order-2 order-md-1">
                    <p class="title-TopicPageBlack">Ścieżka profesjonalna</p>
                    <p class="normalTextBlack text-align-justify"><b>+</b> Idealna dla osób, które chcą
                        tworzyć makijaże komercyjnie.</p>
                    <p class="normalTextBlack text-align-justify"><b>+</b> Nauczysz się profesjonalych techinik pełnej
                        obsługi klienta. Dowiesz się o najnowszych trendach.</p>
                    <p class="normalTextBlack text-align-justify"><b>+</b> Otrzymasz porady dotyczące przeprowadzania
                        wywaidu z Klientem, doboru makijażu dla Klientki, przygotowania niezbędnej dokumentacji.<br></p>
                    <p class="normalTextBlack text-align-justify"><b>+</b> Trzy spotkania, 3x5 godzin.</p>
                    <div class="cena-box">
                        <div class="unbounded-100 cena-box-cenaTitle">Cena:</div>
                        <div class="unbounded-100 cena-box-cenaPrice">1 400zł</div>
                    </div>
                </div>
                <div class="col-md-6 order-1 order-md-2">
                    <div class="photo-frame ofertaDetails-cennik-photo" id="oferta-szkolenia-cennik-thirdPhoto" >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="ofertaDetails white">
        <div class="container ofertaDetails-part3">
            <div class="row normalTextBlack text-align-justify">
                Do każdej cenny doliczane są koszty dojazdu, jeśli szkolenie odbywa się u Klientki. Cena ta
                jest zależna od dystansu i ustalana indywidualnie. <br>
                Jeśli jesteś zainteresowana i chcesz skorzystać z usług lub chcesz poznać więcej szczegółów, to
                zapraszam do kontaktu!<br><br>
                Telefon: <b>{{phoneNumber}} </b><br>
                Email: <b>{{email}}</b> <br><br>
            </div>
        </div>
    </div>
</section>

<app-oferta></app-oferta>