import { Component } from '@angular/core';
import { EnvironmentService } from '../../../services/environment.service';
import { OfertaComponent } from '../oferta.component';
import { MetaService } from '../../../services/meta.service';


@Component({
  selector: 'app-szkolenia',
  standalone: true,
  imports: [OfertaComponent],
  templateUrl: './szkolenia.component.html',
  styleUrl: './szkolenia.component.css'
})
export class SzkoleniaComponent {

  email: string = '';
  phoneNumber: string = '';

  constructor(public environment: EnvironmentService, private metaService:MetaService) { }

  ngOnInit(): void {
    this.email = this.environment.getEmail();
    this.phoneNumber = this.environment.getPhoneNumber();

    this.metaService.updateMetaTags("Pink Lips - Martyna Rodasik - Szkolenia", 
    "Przygotowane ścieżki szkoleniowe specjalnie dla Ciebie. Zacznij naukę Makeup i Glowup. Po ukończonym kursie otrzymasz certyfikat potwierdzający zdobyte umiejętności.")

  }
}
