<footer>
    <div class="footer blue karla-200">
        <div class="container">
            <div class="row footer-border-line">
                <div class="col-md-3 order-1 order-md-1">
                    <div class="footer-leftSide-logo"></div>
                </div>

                <div class="col-md-4 footer-companyInformation order-2 order-md-2">
                    <b class="footer-blueColor unbounded-100">Pink Lips Martyna Rodasik</b><br>
                    43-190 Mikołów<br>
                    ul. Równoległa 45<br>
                    NIP: 6351864031<br><br>
                </div>

                <div class="col-md-3 order-4 order-md-3">
                    <b class="footer-blueColor unbounded-100">Skontaktuj się:</b><br>
                    Tel.: {{phoneNumber}}<br>
                    Email: {{email}}<br>
                    <div class="footer-socialMedia">
                        <a href="https://www.facebook.com/martinarodasik/" aria-label="Firmowy Facebook">
                            <i class="fa-brands fa-facebook footer-socialMediaIcon"></i>
                        </a>
                        <a href="https://www.instagram.com/martinarodasik/" aria-label="Firmowy Instagram">
                            <i class="fa-brands fa-instagram footer-socialMediaIcon"></i>
                        </a>
                        <a href="https://www.tiktok.com/@martynarodasik" aria-label="Firmowy TikTok">
                            <i class="fa-brands fa-tiktok footer-socialMediaIcon"></i>
                        </a>
                        <a href="https://booksy.com/pl-pl/177565_pink-lips-martyna-rodasik_makijaz_11835_mikolow" aria-label="Rezerwacja terminów Booksy">
                            <div class="footer-booksy-logo footer-socialMediaIcon"></div>
                        </a>
                    </div>
                </div>

                <div class="col-md-2 order-3 order-md-4">
                    <b class="footer-blueColor unbounded-100">Linki:</b><br>
                    <!-- <a routerLink="regulamin-uslug">Regulamin usług</a><br> -->
                    <!-- <a routerLink="zgoda-wizerunkowa">Zgoda wizerunkowa</a><br><br> -->
                </div>
            </div>
            <div class="row footer-lastRow" style="text-align: center;">
                <div class="col-md-1"></div>
                <div class="col-md-3">Pink Lips Martyna Rodasik </div>
                <div class="col-md-4">Makijaż, Makeup & Szkolenia - Mikołów, Śląsk, Polska </div>
                <div class="col-md-3">Wykonanie: Szymon Woźnica</div>
                <div class="col-md-1"></div>
            </div>
        </div>
    </div>
</footer>