<section>
    <div class="oferta-first">
        <div class="oferta-first-FullSizeImage">
        </div>
        @if(isOnOfferPage()){
        <div class="oferta-first-textBox">
            <p class="roboto-flex-200 oferta-first-textFirst">OFERTA NASZYCH USŁUG</p>
            <p class="unbounded-100 oferta-first-textSecond">MakeUp, Wizaż & Szkolenia</p>
        </div>
        }
    </div>
</section>

<section>
    <div class="oferta-second white">
        <div class="container">
            <div class="title-TopicPageBlack"> Katalog usług</div>
            <div class="row">
                <div class="col-md-4 ">
                    <div class="ofert-second-box">
                        <div class="photo-frame oferta-second-photos" id="oferta-second-photo1"></div>
                        <div class="normalTextBlack oferta-second-titleBox">PAKIETY ŚLUBNE</div>
                        <div class="normalTextBlack oferta-second-describeBox">
                            Każdy pakiet został starannie zaprojektowany,
                            aby idealnie dopasować się do oczekiwań Młodych Par planujących swoje wymarzone wesele.
                        </div>
                        <a href="oferta/pakiety-slubne">
                            <button class="button-black oferta-second-button">Sprawdź ofertę</button>
                        </a>

                    </div>
                </div>
                <div class="col-md-4 tst">
                    <div class="ofert-second-box">
                        <div class="photo-frame oferta-second-photos" id="oferta-second-photo2"></div>
                        <div class="normalTextBlack  oferta-second-titleBox">MAKEUP & GLOWUP</div>
                        <div class="normalTextBlack oferta-second-describeBox">
                            Usługi makijażu są przygotowane w takich wariantach, aby dopasować się do różnych stylów i
                            preferencji,
                            sprawiając, że każda okazja będzie wyjątkowa.
                        </div>
                        <a href="oferta/makeup-glowup">
                            <button class="button-black oferta-second-button">Sprawdź ofertę</button>
                        </a>
                    </div>
                </div>
                <div class="col-md-4 tst">
                    <div class="ofert-second-box">
                        <div class="photo-frame oferta-second-photos" id="oferta-second-photo3"></div>
                        <div class="normalTextBlack  oferta-second-titleBox">SZKOLENIA</div>
                        <div class="normalTextBlack oferta-second-describeBox">
                            Ścieżki szkoleniowe przygotowane pod Twoje potrzeby. Na zakończenie każdego
                            szkolenia wydajemy certyfikat potwierdzający zdobytą wiedzę.
                        </div>
                        <a href="oferta/szkolenia">
                            <button class="button-black oferta-second-button">Sprawdź ofertę</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>