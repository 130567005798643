import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(private meta: Meta, private titleService: Title) {}

  setCanonicalUrl(url: string) {
    this.meta.addTag({ name: 'canonical', content: url });
  }

  updateMetaTags(title: string, description: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({ name: 'description', content: description });


  }
}