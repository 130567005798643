import { Component, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { EnvironmentService } from '../../services/environment.service';
import { MetaService } from '../../services/meta.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [],
  templateUrl: './main.component.html',
  styleUrl: './main.component.css'
})
export class MainComponent {

  path: string = '/assets/img/photos/'; // Przykładowa ścieżka do obrazów
  images: string[] = ['005_01.jpg', '005_02.jpg', '005_03.jpg', '005_04.jpg', '005_05.jpg','005_06.jpg','005_07.jpg']; // Przykładowa tablica obrazów
  currentIndex: number = 0;
  currentImage: string = this.path + '005_01.jpg';;

  constructor(private ngZone: NgZone, @Inject(PLATFORM_ID) private platformId: any, private metaService:MetaService, private environmentService:EnvironmentService) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.ngZone.runOutsideAngular(() => this.startImageSlider());
    }
    this.metaService.updateMetaTags("Pink Lips - Martyna Rodasik - Strona Główna", 
    "Profesjonalne usługi Make Up z dojazdem do Klienta. Maluję na śluby i wesela, studniówki, sesje zdjęciowe, bale i wiele innych. Zapraszam do zapoznania się z ofertą .")
    this.currentImage = this.path + this.images[this.currentIndex];
    this.metaService.setCanonicalUrl(this.environmentService.getUrl());
  }

  startImageSlider() {
    setInterval(() => {
      this.ngZone.run(() => {
        this.currentIndex = (this.currentIndex + 1) % this.images.length;
        this.currentImage = this.path + this.images[this.currentIndex];
      });
    }, 5000);
  }
}